var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('gmap-map',{ref:"Gmap",staticStyle:{"width":"100%","height":"450px"},attrs:{"center":_vm.mapCenter,"zoom":7,"map-type-id":"roadmap","options":{
      zoomControl: true,
      mapTypeControl: true,
      scaleControl: false,
      streetViewControl: false,
      rotateControl: false,
      fullscreenControl: false,
      disableDefaultUi: false,
      draggable: _vm.mapDraggable,
      draggableCursor: _vm.mapCursor,
    }},on:{"input":function($event){return _vm.update($event)}}},[_c('gmap-polygon',{attrs:{"paths":_vm.paths,"editable":true,"fillOpacity":0.5},on:{"paths_changed":function($event){return _vm.updateEdited($event)}}},[_vm._v(" > ")]),_c('gmap-cluster',_vm._l((_vm.markers),function(m,i){return _c('gmap-marker',{key:i,attrs:{"position":m.position,"clickable":true,"draggable":true},on:{"click":function($event){_vm.center = m.position}}})}),1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }