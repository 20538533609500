<template>
  <v-container>
    <div class="d-block d-md-flex">
      <AppAside></AppAside>
      <div class="ps-5 page_content grow">
        <v-skeleton-loader
          type="table"
          v-if="!data_loaded"
          class="mt-10"
        ></v-skeleton-loader>

        <!--/ sekeletion -->
        <v-card class="mt-10" v-if="data_loaded">
          <v-card-title class="font-weight-bold">
            <div class="d-flex align-center">
              <v-icon x-large>map</v-icon>
              <h1 class="ms-3">איזורים</h1>
            </div>
            <v-spacer></v-spacer>
            <div
              v-if="$store.getters.permission('locations create')"
              class="d-flex"
            >
              <v-btn @click="dialog = true" color="primary"> איזור חדש </v-btn>
            </div>
          </v-card-title>
          <v-data-table
            :mobile-breakpoint="0"
            :headers="headers"
            :items="items"
          >
            <template v-slot:item.actions="{ item }">
              <v-btn
                small
                icon
                v-if="$store.getters.permission('locations edit')"
                @click="edit(item)"
              >
                <v-icon small>edit</v-icon>
              </v-btn>
              <v-btn
                v-if="$store.getters.permission('locations delete')"
                small
                icon
                @click="open_delete_dialog(item.id)"
              >
                <v-icon small>delete</v-icon>
              </v-btn>
            </template>
            <!--/ actions -->
          </v-data-table>
          <!--/ datatable -->
        </v-card>
        <!--/ card -->

        <v-dialog
          fullscreen
          hide-overlay
          transition="dialog-bottom-transition"
          v-model="dialog"
        >
          <v-card>

            <v-card-title>
              <h4 class="black--text">איזור חדש</h4>
            </v-card-title>
            <v-divider></v-divider>
            <v-card-text class="pt-5">
              <v-form ref="form">
                <v-row>
                  <v-col cols="6" sm="4">
                    <v-text-field
                      :rules="[(v) => !!v || 'חובה']"
                      v-model="item.place"
                      outlined
                      dense
                    >
                      <template slot="label">
                        <strong class="red--text">*</strong>
                        שם איזור
                      </template>
                    </v-text-field>
                  </v-col>
                  <v-col cols="6" sm="4">
                    <v-text-field
                      :rules="[(v) => !!v || 'חובה']"
                      v-model="item.min_cart"
                      outlined
                      type="number"
                      dense
                    >
                      <template slot="label">
                        <strong class="red--text">*</strong>
                        מינימום סל
                      </template>
                    </v-text-field>
                  </v-col>

                  <v-col cols="6" sm="4">
                    <v-text-field
                      :rules="[(v) => !!v || 'חובה']"
                      v-model="item.delivery_cost"
                      outlined
                      dense
                    >
                      <template slot="label">
                        <strong class="red--text">*</strong>
                        עלות משלוח
                      </template>
                    </v-text-field>
                  </v-col>
                </v-row>
                <OrderMap
                  v-if="
                    company.id &&
                    company.coordinates.split(',').length == 2 &&
                    dialog
                  "
                  ref="s_map"
                  :st_paths="item.paths"
                  :coordinates="company.coordinates"
                  @update_paths="update_paths($event)"
                ></OrderMap>
              </v-form>
            </v-card-text>
            <v-card-actions>
              <v-btn
                :disabled="save_loader"
                :loading="save_loader"
                color="primary"
                @click="save()"
              >
              שמירה
              </v-btn>
              <v-btn @click="close_dialog()"> ביטול </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>

        <v-dialog max-width="300" v-model="delete_dialog">
          <v-card>
            <v-card-title class="text-center d-block">
              מחק איזור!
            </v-card-title>
            <v-card-actions class="justify-center">
              <v-btn
                :loading="delete_loader"
                @click="delete_item()"
                depressed
                color="error"
                >מחק</v-btn
              >
              <v-btn @click="delete_dialog = false" depressed>ביטול</v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
        <!--/ delete dialog -->
      </div>
    </div>
    <v-snackbar width="auto" right color="error" v-model="snackbar">
      برجاء تحديد المنطقة
      <template v-slot:action="{ attrs }">
        <v-icon
          color="white"
          v-bind="attrs"
          >highlight_off</v-icon
        >
      </template>
    </v-snackbar>

  </v-container>
</template>

<script>
import OrderMap from "@/components/orders/OrderMap";
export default {
  name: "locations",
  data() {
    return {
      company: {
        id: null,
        coordinates: null,
      },
      snackbar:false,
      delete_dialog: false,
      delete_loader: false,
      data_loaded: false,
      save_loader: false,
      delete_id: null,
      headers: [
        {
          value: "place",
          text: "שם איזור",
          align: "center",
        },
        {
          value: "min_cart",
          text: "מינימום סל",
          align: "center",
        },
        {
          value: "delivery_cost",
          text: "עלות משלוח",
          align: "center",
        },
        {
          value: "actions",
          text: "פעולות",
          align: "center",
        },
      ],
      dialog: false,
      item: {
        id: null,
        min_cart: 0,
        place: null,
        paths: [],
        delivery_cost: 0,
      },
    };
  },
  components: {
    OrderMap,
  },
  methods: {
    get_data() {
      this.$store
        .dispatch("public__request", {
          config: {
            url: "places",
            method: "get",
          },
        })
        .then((res) => {
          this.items = Object.assign([], res.data.data.data);
          this.company = Object.assign({}, res.data.data.company);
          this.data_loaded = true;
        })
        .catch((err) => {
          console.log(err.response);
        });
    },
    open_delete_dialog(id) {
      this.delete_id = id;
      this.delete_dialog = true;
    },
    edit(item) {
      this.dialog = true;
      setTimeout(() => {
        this.item = Object.assign({}, item);
      });
    },
    save() {
      if (
        this.$refs.form.validate() &&
        !this.save_loader &&
        (this.item.id
          ? this.$store.getters.permission("locations edit"): this.$store.getters.permission("locations create"))
      ) {
        this.save_loader = true;
        this.$store
          .dispatch("public__request", {
            config: {
              url: this.item.id ? `places/${this.item.id}` : "places",
              method: this.item.id ? `put` : "post",
            },
            data: this.item,
          })
          .then((res) => {
            this.get_data();
            this.close_dialog();
          })
          .catch((err) => {
            if(err.response.data.message.paths) {
              this.snackbar = true;
              this.save_loader = true;
            }
          });
      }
    },
    delete_item() {
      if (
        !this.delete_loader &&
        this.$store.getters.permission("locations delete")
      ) {
        this.delete_loader = true;
        this.$store
          .dispatch("public__request", {
            config: {
              url: `places/${this.delete_id}`,
              method: "delete",
            },
          })
          .then((res) => {
            this.get_data();
            this.delete_loader = false;
            this.delete_dialog = false;
          });
      }
    },
    update_paths(ev) {
      this.item.paths = Object.assign([], ev);
    },
    close_dialog() {
      this.dialog = false;
      this.save_loader = false;
      this.item = Object.assign(
        {},
        {
          id: null,
          min_cart: 0,
          place: null,
          delivery_cost: 0,
          paths: [],
        }
      );

      this.$refs.form.resetValidation();
    },
  },
  mounted() {
    this.get_data();
  },
};
</script>
