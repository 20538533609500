<template>
    <div>
      <gmap-map
        ref="Gmap"
        :center="mapCenter"
        :zoom="7"
        map-type-id="roadmap"
        style="width: 100%; height: 450px"
        @input="update($event)"
        :options="{
          zoomControl: true,
          mapTypeControl: true,
          scaleControl: false,
          streetViewControl: false,
          rotateControl: false,
          fullscreenControl: false,
          disableDefaultUi: false,
          draggable: mapDraggable,
          draggableCursor: mapCursor,
        }"
      >
        <gmap-polygon
          :paths="paths"
          :editable="true"
          :fillOpacity="0.5"
          @paths_changed="updateEdited($event)"
        >
          >
        </gmap-polygon>
        <gmap-cluster>
          <gmap-marker
            v-for="(m, i) in markers"
            :position="m.position"
            :clickable="true"
            :draggable="true"
            @click="center = m.position"
            :key="i"
          >
          </gmap-marker>
        </gmap-cluster>
      </gmap-map>
    </div>
  </template>
  
  <script>
  import GmapVue from "gmap-vue";
  import { components } from "gmap-vue";
  import Vue from "vue";
  Vue.component("gmap-polygon", components.Polygon);
  Vue.component("gmap-cluster", components.Cluster);
  Vue.component("gmap-drawing-manager", components.DrawingManager);
  Vue.use(GmapVue, {
    load: {
      // [REQUIRED] This is the unique required value by Google Maps API
      key: "AIzaSyAeLMCQymseIDBwO6JiaaNL5IpexDGW06w",
      // [OPTIONAL] This is required if you use the Autocomplete plugin
      // OR: libraries: 'places,drawing'
      // OR: libraries: 'places,drawing,visualization'
      // (as you require)
      libraries: "drawing",
  
      // [OPTIONAL] If you want to set the version, you can do so:
      v: "3.26",
  
      // This option was added on v3.0.0 but will be removed in the next major release.
      // If you already have an script tag that loads Google Maps API and you want to use it set you callback
      // here and our callback `GoogleMapsCallback` will execute your custom callback at the end; it must attached
      // to the `window` object, is the only requirement.
      customCallback: "MyCustomCallback",
    },
  
    // [OPTIONAL] If you intend to programmatically custom event listener code
    // (e.g. `this.$refs.gmap.$on('zoom_changed', someFunc)`)
    // instead of going through Vue templates (e.g. `<GmapMap @zoom_changed="someFunc">`)
    // you might need to turn this on.
    autoBindAllEvents: false,
  
    // [OPTIONAL] If you want to manually install components, e.g.
    // import {GmapMarker} from 'gmap-vue/src/components/marker'
    // Vue.component('GmapMarker', GmapMarker)
    // then set installComponents to 'false'.
    // If you want to automatically install all the components this property must be set to 'true':
    installComponents: true,
  
    // Load the Google Maps API dynamically, if you set this to `true` the plugin doesn't load the Google Maps API
    dynamicLoad: false,
  });
  
  export default {
    name: "OrderMap",
    props: ["coordinates", "st_paths"],
    data() {
      return {
        paths: [],
        markers: [],
        latLngArr: [],
        payo: null,
        mode: null,
        mapCenter: {
          lat: 0,
          lng: 0,
        },
        mapMode: null,
        toolbarPosition: "TOP_CENTER",
        mapDraggable: true,
        mapCursor: null,
      };
    },
    watch: {
      coordinates(ev) {
        let coordinates = ev.split(",");
        if (coordinates.length == 2) {
          if (!isNaN(parseFloat(coordinates[1]))) {
            this.mapCenter = {
              lat: parseFloat(coordinates[0]),
              lng: parseFloat(coordinates[1]),
            };
  
            this.markers = [
              {
                position: {
                  lat: parseFloat(coordinates[0]),
                  lng: parseFloat(coordinates[1]),
                },
              },
            ];
          }
        }
      },
    },
  
    methods: {
      updateEdited(ev) {
        ev.Sc[0].Sc.forEach((ev, i) => {
          this.paths[i] = {
            lat: ev.lat(),
            lng: ev.lng(),
          };
          this.latLngArr.push([ev.lat(), ev.lng()]);
          this.$emit("update_paths", this.latLngArr);
        });
      },
      inside(point, vs) {
        var x = point[0],
          y = point[1];
  
        var inside = false;
        for (var i = 0, j = vs.length - 1; i < vs.length; j = i++) {
          var xi = vs[i][0],
            yi = vs[i][1];
          var xj = vs[j][0],
            yj = vs[j][1];
  
          var intersect =
            yi > y != yj > y && x < ((xj - xi) * (y - yi)) / (yj - yi) + xi;
          if (intersect) inside = !inside;
        }
        return inside;
      },
    },
    created() {},
    mounted() {
      if (this.coordinates) {
        let coordinates = this.coordinates.split(",");
        this.mapCenter = {
          lat: parseFloat(coordinates[0]),
          lng: parseFloat(coordinates[1]),
        };
        this.markers = [
          {
            position: {
              lat: parseFloat(coordinates[0]),
              lng: parseFloat(coordinates[1]),
            },
          },
        ];



      }
      let vm = this;
      function getDrawnPolygon(polygon) {
        //polygon we just drew is here.
        var paths = polygon.getPath(); //get an array of latlng
        vm.latLngArr = [];
        paths.getArray().forEach(function (value, index, array_x) {
          var lat = value.lat;
          var lng = value.lng;
          vm.latLngArr.push([lat(), lng()]);
        });
      }
  
      setTimeout(() => {
        if (this.st_paths) {
          this.st_paths.forEach((e) => {
            this.paths.push({
              lat: e[0],
              lng: e[1],
            });
          });
        }
  
        this.mapMode = "ready";
        let map = this.$refs.Gmap.$mapObject;
  
        let drawingManager = new google.maps.drawing.DrawingManager({
          // drawingMode: google.maps.drawing.OverlayType.MARKER,
          drawingControl: true,
          drawingControlOptions: {
            position: google.maps.ControlPosition.TOP_CENTER,
            drawingModes: [
              // google.maps.drawing.OverlayType.MARKER,
              // google.maps.drawing.OverlayType.CIRCLE,
              google.maps.drawing.OverlayType.POLYGON,
              // google.maps.drawing.OverlayType.POLYLINE,
              // google.maps.drawing.OverlayType.RECTANGLE,
            ],
          },
          polygonOptions: {
            fillColor: "#000",
            fillOpacity: 0.5,
            strokeWeight: 3,
            strokeColor: "#333",
            draggable: true,
            editable: true,
            clickable: true,
          },
        });
        let vm = this;
        let all_overlays = [];
        google.maps.event.addListener(
          drawingManager,
          "overlaycomplete",
          function (e) {
            console.log(all_overlays);
            if (all_overlays.length != 0) {
              all_overlays[all_overlays.length - 1].overlay.setMap(null);
            }
            all_overlays.push(e);
          }
        );
        google.maps.event.addListener(
          drawingManager,
          "polygoncomplete",
          function (polygon) {
            vm.payo = polygon;
            vm.paths = [];
            getDrawnPolygon(polygon);
            vm.$emit("update_paths", vm.latLngArr);
            google.maps.event.addListener(
              polygon.getPath(),
              "set_at",
              function (index, obj) {
                vm.$set(vm.latLngArr[index], 0, obj.lat());
                vm.$set(vm.latLngArr[index], 1, obj.lng());
                vm.$emit("update_paths", vm.latLngArr);
              }
            );
          }
        );
        drawingManager.setMap(map);
      }, 1000);
    },
  };
  </script>
  
  <style></style>
  